.header {
  min-height: 100dvh;
  .row {
    min-height: 100dvh;
  }
  .content {
    max-width: 1220px;
  }
  .col-1 {
    background: #D2233C;
    min-height: 100vh;
    .content {
      padding: 60px 40px 80px 50px;
      flex-direction: column;
      justify-content: space-between;
      display: inline-flex;
      h1 {
        padding: 40px 0 40px;
        text-transform: uppercase;
      }
    }
  }
  .col-2 {
    flex-direction: column;
    overflow: hidden;
    .head {
      max-height: 100%;
      height: 101%;
    }
    .content {
      padding-right: 50px;
    }
  }
  .nav {
    display: flex!important;
    justify-content: space-between;
    padding: 30px 0;
    @media screen and (max-width: 940px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 60px 30px;
      @media screen and (max-width: 940px) {
        .Dropdown-root {
          order: 1;
          margin-bottom: 100px;
          .Dropdown-control {
            padding: 0 30px 0 0;
            text-align: left;
            display: flex;
            font-size: 24px;
            font-weight: 400;
            .Dropdown-arrow {
              position: relative;
              margin-left: 10px;
              right: 0;
              top: -4px;
            }
          }
        }
        .menu {
          flex-direction: column;
          order: 2;
          li {
            margin-bottom: 50px;
            font-size: 24px;
          }
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
  .menu {
    display: flex;
    list-style: none;
    font-size: 18px;
    margin: 0;
    a {
      color: #000;
      margin-left: 50px;
      text-decoration: none;
    }
  }
  .head {
    width: 100%;
    object-fit: cover;
  }
}
